import React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

interface ButterBarMenuProps {
  modelName?: string;
  isVisible: boolean;
  hasHybrid?: boolean;
}
export const ButterBarMenu = React.memo<ButterBarMenuProps>(
  ({ modelName, isVisible, hasHybrid }) => {
    return (
      <div
        className={cn(
          styles.butterBarMenu,
          isVisible ? styles.butterMenuVisible : styles.butterMenuHidden
        )}
      >
        <div className={styles.butterBarContainer}>
          <a
            className={styles.butterMenu}
            href={`/brochure-download?model=${modelName}${
              hasHybrid ? `,${modelName} Hybrid` : ''
            }`}
          >
            <img
              src="/dist/assets/images/brochure.png"
              alt="Download a Brochure"
            />
            <span>Download a Brochure</span>
          </a>
          <a className={styles.butterMenu} href="/configure">
            <img src="/dist/assets/images/build.png" alt="Build Your Own" />
            <span>Build Your Own</span>
          </a>
          <a
            className={styles.butterMenu}
            href={`/book-test-drive?model=${modelName}`}
          >
            <img
              src="/dist/assets/images/test-drive.png"
              alt="Book a Test Drive"
            />
            <span>Book a Test Drive</span>
          </a>
          <a className={styles.butterMenu} href="/value-my-car">
            <img
              src="/dist/assets/images/value-my-car.png"
              alt="Value My Car"
            />
            <span>Value My Car</span>
          </a>
          <a className={styles.butterMenu} href="/retailers">
            <img src="/dist/assets/images/location.png" alt="Find a Dealer" />
            <span>Find a Retailer</span>
          </a>
        </div>
      </div>
    );
  }
);
